import { Flex, mdBumps, TertiaryButton, Typo } from '@wrisk/ui-components'
import React, { useCallback } from 'react'

import { getCoreAsset, Quote } from '../../../domain'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { ExcessConfig } from '../../../state/configuration'
import { usePrincipal } from '../../authentication'
import { useMaybeProposal } from '../../product/proposal/proposalContext'

interface VoluntaryExcessProps {
  config: ExcessConfig
  quote: Quote
  onEdit: (excess: number) => void
}

const tKey = TKey('components.voluntary-excess')

export const VoluntaryExcess: React.FC<VoluntaryExcessProps> = ({
  config,
  quote,
  onEdit,
}) => {
  const { t } = useWriskTranslation()
  const { isAdmin } = usePrincipal()

  const proposal = useMaybeProposal() // TODO(MK): Break this dependency

  const { voluntaryExcess } = getCoreAsset(quote)

  const onExcessChange = useCallback(() => {
    onEdit(voluntaryExcess)
  }, [voluntaryExcess, onEdit])

  const isAdjustable = Boolean(proposal || config.isAdjustable || isAdmin)

  return (
    <Flex variant='raised' columnGap={2} width={1} px={mdBumps} py={5}>
      <Flex
        flexDirection='column'
        alignItems='flex-start'
        justifyContent='center'
        rowGap={1}
      >
        <Typo typoSize='xs'>{t(tKey('header'))}</Typo>
        <Typo>{t(tFormats('currency.short'), { amount: voluntaryExcess })}</Typo>
      </Flex>
      {isAdjustable && (
        <TertiaryButton
          layout='small'
          onClick={onExcessChange}
          data-testid='open-excess-modal'
        >
          {t(tKey('actions.edit'))}
        </TertiaryButton>
      )}
    </Flex>
  )
}
