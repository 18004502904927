import { ModalProvider, PageLoadingIndicator } from '@wrisk/ui-components'
import React, { FunctionComponent, PropsWithChildren } from 'react'
import { Navigate } from 'react-router-dom'

import { with404ErrorHandling } from '../../../clients/api'
import { useApiErrorHandlingAsync } from '../../../hooks/auth'
import { useRequiredParams } from '../../../infrastructure/routing'
import { AppPath } from '../../AppPath'
import { usePrincipal } from '../../authentication'
import { useProposalTracking } from '../../tracking'
import { PolicyProvider } from './policyContext'
import { PolicyParams } from './PolicyPath'
import { PolicyRouter } from './PolicyRouter'

export const ManagePolicy: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { policyId } = useRequiredParams<PolicyParams>()

  const { apiClient } = usePrincipal()
  const tracking = useProposalTracking()

  const getPolicyAsync = useApiErrorHandlingAsync(async () => {
    const policy = await with404ErrorHandling(
      async () => await apiClient.getPolicy(policyId),
    )
    tracking.trackImpression(policy)
    return policy
  }, [policyId])

  return getPolicyAsync.loading ? (
    <PageLoadingIndicator />
  ) : getPolicyAsync.result ? (
    <PolicyProvider policy={getPolicyAsync.result}>
      <ModalProvider>
        <PolicyRouter>{children}</PolicyRouter>
      </ModalProvider>
    </PolicyProvider>
  ) : (
    <Navigate to={AppPath.HOME} />
  )
}
