import { ModalProvider, PageLoadingIndicator } from '@wrisk/ui-components'
import React, { FunctionComponent, PropsWithChildren } from 'react'
import { Navigate } from 'react-router-dom'

import { with404ErrorHandling } from '../../../clients/api'
import { useApiErrorHandlingAsync } from '../../../hooks/auth'
import { useRequiredParams } from '../../../infrastructure/routing'
import { useAnonymous } from '../../../state'
import { AppPath } from '../../AppPath'
import { usePrincipal } from '../../authentication'
import { useProposalTracking } from '../../tracking'
import { ProposalProvider } from './proposalContext'
import { ProposalParams } from './ProposalPath'
import { ProposalRouter } from './ProposalRouter'

export const ManageProposal: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { proposalCode } = useRequiredParams<ProposalParams>()

  const { apiClient, isAuthenticated } = usePrincipal()
  const { getToken } = useAnonymous()

  const tracking = useProposalTracking()

  const getProposalAsync = useApiErrorHandlingAsync(async () => {
    let proposal = await with404ErrorHandling(
      async () => await apiClient.getProposal(proposalCode),
    )

    if (!proposal && isAuthenticated) {
      const anonymousToken = await getToken()
      proposal = anonymousToken
        ? await with404ErrorHandling(
            async () => await apiClient.mergeProposal(proposalCode, anonymousToken),
          )
        : undefined
    }

    tracking.trackImpression(proposal)

    return proposal
  }, [proposalCode, getToken])

  const proposal = getProposalAsync.result
  const isProposalLoading = proposal && proposalCode !== proposal.proposalCode

  return getProposalAsync.loading || isProposalLoading ? (
    <PageLoadingIndicator />
  ) : proposal ? (
    <ProposalProvider proposal={proposal}>
      <ModalProvider>
        <ProposalRouter>{children}</ProposalRouter>
      </ModalProvider>
    </ProposalProvider>
  ) : (
    <Navigate to={AppPath.HOME} />
  )
}
