import {
  ConfigurationState,
  DocumentConfig,
  DocumentType,
  FeatureToggles,
  ProductConfig,
  ProductDocument,
  StaticDocument,
} from './types'

export const getExposuresContentBaseUrl =
  (schemeCode: string) =>
  (state: ConfigurationState): string =>
    `${state.config.contentBaseUrl}/${schemeCode}/exposures`

export const getHelpLink =
  (name: string) =>
  (state: ConfigurationState): string =>
    `${state.config.contentBaseUrl}/questions/${name}.html`

export const getSchemes = (state: ConfigurationState) => state.config.schemes

export const getSchemeByCode = (schemeCode: string) => (state: ConfigurationState) => {
  const scheme = state.config.schemes.find((pl) => pl.schemeCode === schemeCode)
  if (!scheme) {
    throw new Error(`No scheme configured for the scheme code ${schemeCode}`)
  }
  return scheme
}

export const getProduct = (schemeCode: string) => (state: ConfigurationState) =>
  state.config.productConfig[schemeCode]

export const getProducts = (state: ConfigurationState): Record<string, ProductConfig> =>
  state.config.productConfig

export const getStaticDocuments =
  (schemeCode: string) =>
  (state: ConfigurationState): StaticDocument[] =>
    getProduct(schemeCode)(state)
      ?.documents.filter((d) => d.type === DocumentType.STATIC)
      .map((document) => ({
        ...document,
        type: DocumentType.STATIC,
      })) ?? []

export const getFeatureToggles = ({ config }: ConfigurationState) =>
  config.features ?? ({} as FeatureToggles)

export const getProposalTrackingNames = ({ config }: ConfigurationState) =>
  config.proposalTracking ?? []

export const getCoreAssetType = ({ assetTypes }: ProductConfig) => {
  const assetType = assetTypes.find((it) => it.core)

  if (!assetType) {
    throw new Error('No core asset for product')
  }
  return assetType
}

export const getAccountInputs = ({ config }: ConfigurationState) => config.account ?? []

export const getRetrieve = ({ config }: ConfigurationState) => config.retrieve

export const getFooter = ({ config }: ConfigurationState) => config.footer ?? []

export const isProductDocument = (doc: DocumentConfig): doc is ProductDocument =>
  doc.type === DocumentType.PRODUCT
