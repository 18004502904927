import { InvoiceItemCell, mdBumps, xxsBumps } from '@wrisk/ui-components'
import { Duration } from 'luxon'
import React, { FunctionComponent } from 'react'

import {
  CustomerStatementLineItem,
  isJournalLineItem,
  LineItemType,
} from '../../../domain'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { toDateTime } from '../../../util/date'

export interface InvoiceItemProps {
  item: CustomerStatementLineItem
}

const tKey = TKey('components.invoice-item')

export const InvoiceItem: FunctionComponent<InvoiceItemProps> = ({ item }) => {
  const { t } = useWriskTranslation()

  const unknownKey = tKey('unknown')

  const header = (() => {
    switch (item.type) {
      case LineItemType.INVOICE:
      case LineItemType.CREDIT_NOTE:
        return t<string>(
          [
            tKey('statement-descriptions', item.descriptionKey ?? 'not exists'),
            unknownKey,
          ],
          { value: item.descriptionValue },
        )
      case LineItemType.DEBIT_JOURNAL:
      case LineItemType.CREDIT_JOURNAL:
        return t<string>([tKey('journal-descriptions', item.type), unknownKey])
      case LineItemType.PAYMENT:
      case LineItemType.PAYMENT_REVERSAL:
      case LineItemType.REFUND:
      case LineItemType.REFUND_REVERSAL:
        return item.description ?? t<string>(unknownKey)
    }
  })()

  const subheader = isJournalLineItem(item)
    ? undefined
    : item.servicePeriodStart
    ? item.servicePeriodEnd
      ? t<string>(tFormats('dateRange.medium'), {
          start: toDateTime(item.servicePeriodStart),
          end: toDateTime(item.servicePeriodEnd)?.minus(Duration.fromMillis(1)),
        })
      : t<string>(tFormats('date.medium'), {
          value: toDateTime(item.servicePeriodStart),
        })
    : undefined

  return (
    <InvoiceItemCell
      header={header}
      subheader={subheader}
      premium={t<string>(tFormats('currency.long'), {
        amount: item.amount / 100,
      })}
      px={mdBumps}
      py={xxsBumps}
    />
  )
}
