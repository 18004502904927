import { Box, ModalHeader } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  useProposalAdjustment,
  useProposalFormData,
} from '../../../hooks/adjustments/proposal'
import {
  TExtends,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { AdjustmentInputConfig } from '../../../state/configuration'
import { FormDisabled } from '../../formBuilder'

export interface EditOptionalCoverModalProps {
  inputs: AdjustmentInputConfig[]
  onComplete: () => void
}

const tKey = TKey('components.edit-optional-cover-modal')

export const ProposalEditOptionalCoverModal: FunctionComponent<
  EditOptionalCoverModalProps
> = ({ inputs, onComplete }) => {
  const { t } = useWriskTranslation()

  const data = useProposalFormData(inputs)
  const onSubmitAdjustment = useProposalAdjustment(inputs, true, onComplete)

  return (
    <Box>
      <ModalHeader header={t(tKey('header'))} />
      <FormDisabled
        id='editOptionalCover'
        data={data}
        inputs={inputs}
        onSubmit={onSubmitAdjustment.execute}
        loading={onSubmitAdjustment.loading}
        tKey={TExtends(tKey, 'inputs')}
        options={{ shouldUnregister: true }}
        t={t}
      />
    </Box>
  )
}
