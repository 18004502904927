import { isNil } from 'lodash'
import { useMemo } from 'react'

import {
  AccrualType,
  CoverType,
  getCovers,
  getCoversByCoverType,
  PriceOption,
  Quote,
} from '../domain'
import { getCoreAssetType, ProductConfig } from '../state/configuration'

export const useIncludedCovers = (product: ProductConfig): string[] =>
  useMemo(() => getCoreAssetType(product).covers.included, [product])

export const useMainCovers = (quote: Quote | undefined): PriceOption[] =>
  useMemo(() => {
    if (!quote) return []

    return getCoversByCoverType(quote.priceBreakdown, CoverType.MAIN)
  }, [quote])

export const useDiscountCovers = (quote: Quote | undefined): PriceOption[] =>
  useMemo(() => {
    if (!quote) return []

    return getCoversByCoverType(quote.priceBreakdown, CoverType.DISCOUNT)
  }, [quote])

export const useOptionalCovers = (
  quote: Quote | undefined,
  product: ProductConfig,
  addOns: boolean | undefined = undefined,
): PriceOption[] =>
  useMemo(() => {
    if (!quote) return []

    const {
      covers: { optional = [] },
    } = getCoreAssetType(product)

    return optional
      .filter((cover) => isNil(addOns) || (addOns ? !cover.inputs : cover.inputs))
      .flatMap((cover) =>
        getCovers(quote.priceBreakdown, AccrualType.MONTHLY, cover.code),
      )
  }, [addOns, product, quote])
