import {
  Box,
  BoxProps,
  Flex,
  SectionContent,
  SectionDescription,
  SectionTitle,
} from '@wrisk/ui-components'
import React, { FunctionComponent, ReactElement, ReactNode } from 'react'

import { getProductDocuments, Quote } from '../../../domain'
import { getStaticDocuments, useConfig } from '../../../state/configuration'
import { useProduct } from '../../product/productContext'
import { ProductDocumentLink } from './ProductDocumentLink'
import { StaticDocumentLink } from './StaticDocumentLink'

export interface ProductDocumentsSectionProps extends BoxProps {
  quote: Quote
  additionalDocuments?: ReactElement[]
  header?: string
  subheader?: ReactNode
}

export const ProductDocumentsSection: FunctionComponent<ProductDocumentsSectionProps> = ({
  quote,
  additionalDocuments = [],
  header,
  subheader,
  ...props
}) => {
  const { scheme } = useProduct()

  const productDocuments = useConfig(getProductDocuments(scheme, quote)).map(
    (document) => <ProductDocumentLink key={document.id} {...document} />,
  )

  const staticDocumentsConfig = useConfig(getStaticDocuments(scheme.schemeCode))
  const staticDocuments = staticDocumentsConfig.map((document) => (
    <StaticDocumentLink key={document.id} {...document} />
  ))

  const documents = [...additionalDocuments, ...productDocuments, ...staticDocuments]

  return (
    <Box width={1} {...props}>
      {header && <SectionTitle>{header}</SectionTitle>}
      {subheader && <SectionDescription>{subheader}</SectionDescription>}
      <SectionContent>
        <Flex width={1} py={2} flexWrap='wrap' variant='raised' flexDirection='column'>
          {documents}
        </Flex>
      </SectionContent>
    </Box>
  )
}
