import { Box, Flex, FlexProps, lgBumps, PrimaryButton } from '@wrisk/ui-components'
import React, { FunctionComponent, useMemo } from 'react'

import {
  getPolicyVersion,
  mergePriceBreakdowns,
  Policy,
  PriceOption,
} from '../../../../domain'
import {
  tDefaults,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { useProduct } from '../../productContext'
import { usePolicy } from '../policyContext'
import { PolicySidebarPricing } from '../pricing'

export interface AddOnsSideProps extends FlexProps {
  optionalCovers: PriceOption[]
  onContinue: () => void
  loading: boolean
}

const tKey = TKey('components.policy-add-ons-sidebar')

export const PolicyAddOnsSidebar: FunctionComponent<AddOnsSideProps> = ({
  optionalCovers,
  onContinue,
  loading,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const { policy } = usePolicy()
  const { product } = useProduct()

  const { quote } = getPolicyVersion(policy)

  const updatedPolicy = useMemo<Policy>(
    () => ({
      ...policy,
      currentPolicyVersion: {
        ...policy.currentPolicyVersion,
        quote: {
          ...quote,
          priceBreakdown: mergePriceBreakdowns(optionalCovers, quote.priceBreakdown),
        },
      },
    }),
    [policy, quote, optionalCovers],
  )

  return (
    <Flex width={1} flexDirection='column' variant='raised' mb={lgBumps} {...props}>
      <PolicySidebarPricing
        policy={updatedPolicy}
        product={product}
        expanded={true}
        borderBottomWidth={1}
        width={1}
      />
      <Box p={5} width={1} data-testid='button-continue'>
        <PrimaryButton
          loading={loading}
          width={1}
          onClick={onContinue}
          data-testid='button-continue'
        >
          {t([tKey('actions.continue'), tDefaults('actions.continue')])}
        </PrimaryButton>
      </Box>
    </Flex>
  )
}
