import React, { FunctionComponent, PropsWithChildren } from 'react'
import { Navigate } from 'react-router-dom'

import { isRenewalProposal, ProposalStatus } from '../../../domain'
import { AppPath } from '../../AppPath'
import { useProposal } from './proposalContext'
import {
  ProposalExpiredRoutes,
  ProposalInsufficientAccuracyRoutes,
  ProposalPolicyRoutes,
  ProposalQuoteRoutes,
  ProposalReferredRoutes,
} from './routes'
import { ProposalRenewalRoutes } from './routes/ProposalRenewalRoutes'

export const ProposalRouter: FunctionComponent<PropsWithChildren> = () => {
  const { proposal } = useProposal()

  if (isRenewalProposal(proposal)) {
    return proposal.sourcePolicy.detail.renewalStopped ? (
      <Navigate to={AppPath.HOME} />
    ) : (
      <ProposalRenewalRoutes />
    )
  }

  switch (proposal.status) {
    case ProposalStatus.REFERRAL:
      return <ProposalReferredRoutes />
    case ProposalStatus.QUOTE_EXPIRED:
      return <ProposalExpiredRoutes />
    case ProposalStatus.QUOTE:
      return <ProposalQuoteRoutes />
    case ProposalStatus.INSUFFICIENT_ACCURACY:
      return <ProposalInsufficientAccuracyRoutes />
    case ProposalStatus.POLICY:
      return <ProposalPolicyRoutes />
  }
}
