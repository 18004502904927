export enum AppPath {
  HOME = '/',

  PRODUCT = '/:productUrlName',
  PROPOSAL = '/:productUrlName/proposal/:proposalCode',
  POLICY = '/:productUrlName/policy/:policyId',

  RETRIEVE = '/retrieve',
  CALLBACK = '/callback',
  SIGN_IN = '/sign-in',
  ACCOUNT = '/account',

  WILDCARD = '/*',
}
