import { ModalProvider } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { getProduct, Scheme, useConfig } from '../../state/configuration'
import { AppPath } from '../AppPath'
import { ProtectedRoutes } from '../authentication'
import { DirectDebitCallbackWrapper } from '../organisms/billing'
import { ManagePolicy } from './policy'
import { PolicyPath } from './policy/PolicyPath'
import { ProductContext } from './productContext'
import { CreateProposal, ManageProposal } from './proposal'
import { ProposalPath } from './proposal/ProposalPath'
import { RefreshAnonymous } from './RefreshAnonymous'

export interface ProductRouterProps {
  scheme: Scheme
}

export const ProductRouter: FunctionComponent<ProductRouterProps> = ({ scheme }) => {
  const product = useConfig(getProduct(scheme.schemeCode))

  return (
    <DirectDebitCallbackWrapper>
      <ProductContext.Provider value={{ scheme, product }}>
        <ModalProvider>
          <Routes>
            <Route
              key='create'
              index
              element={
                <RefreshAnonymous>
                  <CreateProposal />
                </RefreshAnonymous>
              }
            />

            <Route
              key='proposal'
              path={ProposalPath.PROPOSAL + AppPath.WILDCARD}
              element={<ManageProposal />}
            />

            <Route
              key='policy'
              path={PolicyPath.POLICY + AppPath.WILDCARD}
              element={
                <ProtectedRoutes preservePath={true}>
                  <ManagePolicy />
                </ProtectedRoutes>
              }
            />

            <Route path='*' element={<Navigate to={AppPath.HOME} />} />
          </Routes>
        </ModalProvider>
      </ProductContext.Provider>
    </DirectDebitCallbackWrapper>
  )
}
