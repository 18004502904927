export interface CustomerStatement {
  statementSummary: CustomerStatementSummary
  lineItems: CustomerStatementLineItem[]
}

export interface CustomerStatementSummary {
  statementNumber: number
  statementBalance: number
  subtotalBalance: number
  amountSettled: number
  amountOutstanding: number
  carriedForwardBalance: number
  broughtForwardBalance: number
  openedAt: string
  paymentDueAt: string | null
  createdAt: string
  status: CustomerStatementStatus
  final: boolean
}

export enum CustomerStatementStatus {
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  SETTLED = 'SETTLED',
  DRAFT = 'DRAFT',
}

export interface CustomerStatementLineItem {
  description?: string
  descriptionKey: string | null
  descriptionValue: string | null
  amount: number
  type: LineItemType
  subType: LineItemSubType
  status: LineItemStatus
  group: LineItemGroup
  effectiveAt: string
  servicePeriodStart?: string
  servicePeriodEnd?: string
}

export enum LineItemType {
  INVOICE = 'INVOICE', // descriptionKey/descriptionValue
  CREDIT_NOTE = 'CREDIT_NOTE', // descriptionKey/descriptionValue
  DEBIT_JOURNAL = 'DEBIT_JOURNAL', // none
  CREDIT_JOURNAL = 'CREDIT_JOURNAL', // none
  PAYMENT = 'PAYMENT', // description
  PAYMENT_REVERSAL = 'PAYMENT_REVERSAL', // description
  REFUND = 'REFUND', // description
  REFUND_REVERSAL = 'REFUND_REVERSAL', // description
}

export enum LineItemSubType {
  GOCARDLESS = 'GOCARDLESS',
  STRIPE = 'STRIPE',
  MTA = 'MTA',
}

export enum LineItemStatus {
  PENDING = 'PENDING',
  POSTED = 'POSTED',
  FAILED = 'FAILED',
}

export enum LineItemGroup {
  INVOICE = 'INVOICE',
  PAYMENT = 'PAYMENT',
}
