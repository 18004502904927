import css from '@styled-system/css'
import styled from 'styled-components'
import { compose, ResponsiveValue, space, SpaceProps, variant } from 'styled-system'

import { Image } from '../images'
import { Box, lgBumps } from '../layout'

export type PromotionBannerContainerProperty = 'default' | 'narrow'

export interface PromotionBannerContainerProps extends SpaceProps {
  variant?: ResponsiveValue<PromotionBannerContainerProperty>
  backgroundImage?: string
}

export const PromotionBannerContainer = styled.div<PromotionBannerContainerProps>(
  ({ backgroundImage }) =>
    css({
      color: 'foregroundPromoBanner',
      backgroundColor: 'backgroundPromoBanner',
      backgroundImage: backgroundImage ? `url('${backgroundImage}')` : undefined,
      backgroundSize: 'cover',
      backgroundPosition: '0px 0px',
      borderRadius: 2,
      p: lgBumps,
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'relative',
    }),
  variant({
    scale: 'PromotionBannerContainer',
    variants: {
      default: {
        [Box]: {
          maxWidth: [1, 1, '400px', '500px'],
        },
        [Image.toString()]: {
          display: ['none', 'none', 'block'],
          width: ['200px', '200px', '300px', '400px'],
          bottom: [0, 0, '-32px', '-48px'],
          position: 'absolute',
          right: 0,
        },
      },
      narrow: {
        [Box]: {
          maxWidth: '420px',
        },
        [Image.toString()]: {
          display: ['none', 'none', 'none', 'block'],
          width: ['200px', '200px', '200px', '260px', '300px'],
          bottom: [0, 0, '-16px', '-24px'],
          position: 'absolute',
          right: 0,
        },
      },
    },
  }),
  compose(space),
)

PromotionBannerContainer.defaultProps = {
  variant: 'default',
}
