import { BoxProps, Flex, mdBumps, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'

const tKey = TKey('components.main-excess')

export interface MainExcessSummaryProps extends BoxProps {
  value: number
}

export const MainExcessSummary: FunctionComponent<MainExcessSummaryProps> = ({
  value,
}) => {
  const { t } = useWriskTranslation()

  return (
    <Flex
      variant='raised'
      flexDirection='column'
      alignItems='flex-start'
      justifyContent='center'
      width={1}
      rowGap={1}
      px={mdBumps}
      py={5}
    >
      <Typo typoSize='xs'>{t(tKey('header'))}</Typo>
      <Typo>{t(tFormats('currency.short'), { amount: value })}</Typo>
    </Flex>
  )
}
