import { differenceWith, isEmpty, isEqual, isNil, sum, unionBy } from 'lodash'
import { DateTime } from 'luxon'

import {
  ConfigurationState,
  getProduct,
  isProductDocument,
  ProductDocument,
  Scheme,
} from '../state/configuration'
import {
  AccrualType,
  AddCoverDisclosureChange,
  CoverType,
  PolicyChangeType,
  PriceOption,
  Quote,
  RemoveCoverDisclosureChange,
} from './types'

export const isCoverActive = (priceOption: PriceOption) => priceOption.active

export const getPremiumByAccrualType = (
  priceBreakdown: PriceOption[],
  accrualType: AccrualType,
) =>
  sum(
    getCoversByAccrualType(priceBreakdown, accrualType).map((it) => Math.abs(it.premium)),
  )

export const getCovers = (
  priceBreakdown: PriceOption[],
  accrualType: AccrualType,
  coverCode: string,
) => getCoversByCoverCode(getCoversByAccrualType(priceBreakdown, accrualType), coverCode)

export const getCoversByCoverType = (
  priceBreakdown: PriceOption[],
  coverType: CoverType,
) => priceBreakdown.filter((it) => it.coverType === coverType)

export const getCoversByAccrualType = (
  priceBreakdown: PriceOption[],
  accrualType: AccrualType,
) => priceBreakdown.filter((it) => it.accrualType === accrualType)

export const hasCoverCode =
  (coverCode: string | undefined) => (priceOption: PriceOption) =>
    priceOption.coverCode === coverCode

export const getCoversByCoverCode = (priceBreakdown: PriceOption[], coverCode: string) =>
  priceBreakdown.filter((it) => it.coverCode === coverCode)

export interface GeneratedProductDocument extends ProductDocument {
  url: string
}

export const getProductDocuments =
  (scheme: Scheme, quote: Quote) =>
  (state: ConfigurationState): GeneratedProductDocument[] => {
    const product = getProduct(scheme.schemeCode)(state)
    if (!product) return []

    const generateDocumentUrl = (parts: {
      document: ProductDocument
      insurer: string
      wordingVersion: string
    }) => `/documents/${parts.wordingVersion}/${parts.insurer}.${parts.document.id}.html`

    return product.documents
      .filter(isProductDocument)
      .map((doc) => ({
        doc,
        priceOption: quote.priceBreakdown
          .filter(hasCoverCode(doc.coverCode))
          .find(isCoverActive),
      }))
      .filter(({ doc, priceOption }) => isNil(doc.coverCode) || !isNil(priceOption))
      .map(({ doc, priceOption }) => ({
        ...doc,
        url: generateDocumentUrl({
          document: doc,
          insurer: priceOption?.insurer ?? quote.mainInsurer,
          wordingVersion: quote.wordingVersion,
        }),
      }))
  }

export const coverDifferencesToDisclosureChange = (
  assetCode: string,
  updatedCovers: PriceOption[],
  originalCovers: PriceOption[],
): Array<AddCoverDisclosureChange | RemoveCoverDisclosureChange> => {
  const differences = differenceWith(
    updatedCovers.map((it) => ({ ...it, active: Boolean(it.active) })),
    originalCovers,
    isEqual,
  )

  return differences.map(({ active, coverCode }) => ({
    changeType: active ? PolicyChangeType.ADD_COVER : PolicyChangeType.REMOVE_COVER,
    assetCode,
    coverCode,
  }))
}

export const areCoversEqual = (left: PriceOption[], right: PriceOption[]) =>
  isEmpty(differenceWith(left, right, isEqual))

export const mergePriceBreakdowns = (
  updates: PriceOption[],
  original: PriceOption[],
): PriceOption[] =>
  unionBy(updates, original, (cover) => `${cover.coverCode}-${cover.accrualType}`)

export const arePremiumsEqual = (previous: Quote, current: Quote) =>
  areCoversEqual(previous.priceBreakdown, current.priceBreakdown)

export const inForce = (price: PriceOption | undefined): boolean =>
  Boolean(
    price?.active &&
      (isNil(price.endAt) || DateTime.local() < DateTime.fromISO(price.endAt)),
  )

export const isPriceOptionRemoved = (
  previous: PriceOption | undefined,
  current: PriceOption | undefined,
): boolean => {
  if (inForce(previous)) {
    return !inForce(current)
  } else {
    return false
  }
}
