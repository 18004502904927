export enum ProposalPath {
  PROPOSAL = 'proposal/:proposalCode',
  ADD_ONS = 'add-ons',
  OPT_OUT = 'opt-out',
  CHECKOUT = 'checkout',
  UPGRADE = 'upgrade',
  DISCLOSURE = 'disclosure',
  ACCOUNT_SETUP = 'account-setup',
  SUMMARY = 'summary',
}

export interface ProposalParams extends Record<string, string> {
  proposalCode: string
}
