import { Box, Flex, Heading, mdBumps, smBumps, Typo } from '@wrisk/ui-components'
import { negate } from 'lodash'
import React from 'react'

import { getCoreAsset, Quote } from '../../../domain'
import { isMainExcess } from '../../../domain/excess'
import {
  tExcesses,
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'

export interface OtherExcessProps {
  quote: Quote
}

const tKey = TKey('components.other-excesses')

export const OtherExcesses: React.FC<OtherExcessProps> = ({ quote }) => {
  const { t } = useWriskTranslation()

  const excesses = getCoreAsset(quote).mandatoryExcessLines.filter(negate(isMainExcess))

  return excesses.length ? (
    <Box mt={mdBumps} width={1}>
      <Box my={smBumps}>
        <Heading typoSize='md'>{t(tKey('header'))}</Heading>
      </Box>
      <Flex px={mdBumps} py={2} variant='raised' flexDirection='column' width={1}>
        {excesses.map((it) => (
          <Flex key={it.excessCategory} justifyContent='space-between' width={1} py={3}>
            <Typo>{t(tExcesses(it.excessCategory, 'name'))}</Typo>
            <Typo>{t(tFormats('currency.short'), { amount: it.excess })}</Typo>
          </Flex>
        ))}
      </Flex>
    </Box>
  ) : null
}
