const isExactMatch = (s: string, pattern: RegExp): boolean => {
  const match = pattern.exec(s)
  return (match && match[0] === match.input) || false
}

export const isExactMatchForAny = function (s: string, patterns: RegExp[]): boolean {
  return patterns.map((pattern) => isExactMatch(s, pattern)).some((result) => result)
}

const findMatches = function (s: string, patterns: RegExp[]): RegExpMatchArray[] {
  return patterns
    .map((pattern) => pattern.exec(s))
    .filter((match) => !!match)
    .map((match) => match as RegExpMatchArray)
}

export const findBestMatch = function (
  s: string,
  patterns: RegExp[],
): RegExpMatchArray | undefined {
  const matchArrays = findMatches(s, patterns)
  return matchArrays.length > 0
    ? matchArrays.reduce((match: RegExpMatchArray, otherMatch: RegExpMatchArray) =>
        (match.input?.length as number) - match[0].length <
        (otherMatch.input?.length as number) - otherMatch[0].length
          ? match
          : otherMatch,
      )
    : undefined
}
