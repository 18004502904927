import { Flex, FlexProps, mdBumps, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { Excess } from '../../../../domain/types/excess'
import {
  tExcesses,
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'

const tKey = TKey('components.main-excess')

export interface MainExcessBreakdownProps extends FlexProps {
  excesses: Excess[]
}

export const MainExcessBreakdown: FunctionComponent<MainExcessBreakdownProps> = ({
  excesses,
  ...props
}) => {
  const { t } = useWriskTranslation()

  return (
    <Flex
      p={mdBumps}
      variant='raised'
      flexDirection='column'
      alignItems='flex-start'
      width={1}
      rowGap={1}
      {...props}
    >
      <Typo typoSize='xs'>{t(tKey('header'))}</Typo>
      {excesses.map((it) => (
        <Flex key={it.excessCategory} width={1}>
          <Typo>{t(tExcesses(it.excessCategory, 'name'))}</Typo>
          <Typo>{t(tFormats('currency.short'), { amount: it.excess })}</Typo>
        </Flex>
      ))}
    </Flex>
  )
}
