import {
  Section,
  SectionContent,
  SectionTitle,
  xsBumps,
  xxlBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  getCurrentPolicyVersion,
  PolicyAdjustmentResponse,
} from '../../../../../../domain'
import {
  TKey,
  useWriskTranslation,
} from '../../../../../../infrastructure/internationalisation'
import { ManagementConfig, ManagementType } from '../../../../../../state/configuration'
import { AdjustedProfileExcess } from '../../../../../organisms/policy'
import { PolicyChangesFlex, ProfileChangesFlex } from './components'

export interface PolicyChangesFlexProps {
  result: PolicyAdjustmentResponse
  config: ManagementConfig
}

const tKey = TKey('components.adjustment-config-changes')

export const AdjustmentConfigChanges: FunctionComponent<PolicyChangesFlexProps> = ({
  result,
  config,
}) => {
  const { t } = useWriskTranslation()

  const change = (() => {
    switch (config.type) {
      case ManagementType.ADDITIONAL_PROFILE:
        return <ProfileChangesFlex config={config} result={result} />
      default:
        return <PolicyChangesFlex config={config} result={result} />
    }
  })()

  const policyVersion = getCurrentPolicyVersion(result.updatedPolicyVersions)

  return (
    <Section width={1} mb={xxlBumps}>
      <SectionTitle>{t(tKey('header'))}</SectionTitle>
      <SectionContent>
        {change}
        <AdjustedProfileExcess updatedPolicyVersion={policyVersion} mt={xsBumps} />
      </SectionContent>
    </Section>
  )
}
