import { PageLoadingIndicator } from '@wrisk/ui-components'
import { Settings } from 'luxon'
import React, { FunctionComponent, PropsWithChildren } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useErrorHandlingAsync } from '../../hooks/errors'
import { getFeatureToggles, useConfig } from '../../state/configuration'
import { useWriskTranslation } from './hooks'

const Switcher: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { i18n } = useWriskTranslation()
  const [params] = useSearchParams()

  const async = useErrorHandlingAsync(async () => {
    const locale = params.get('locale')
    const currency = params.get('currency')

    if (currency) {
      i18n.options
      await i18n.init({
        ...i18n.options,
        interpolation: {
          ...i18n.options.interpolation,
          defaultVariables: {
            ...i18n.options.interpolation?.defaultVariables,
            currency,
          },
        },
      })
    }

    if (locale) {
      await i18n.changeLanguage(locale)
      Settings.defaultLocale = locale
    }
  }, [])

  return async.loading ? <PageLoadingIndicator /> : <>{children}</>
}

export const LocalisationSwitcher: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { localisationSwitcher } = useConfig(getFeatureToggles)
  return localisationSwitcher ? <Switcher>{children}</Switcher> : <>{children}</>
}
