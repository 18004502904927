import { HelpLinkIFrame } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { getExposuresContentBaseUrl, useConfig } from '../../../state/configuration'
import { useProduct } from '../../product/productContext'

interface AddOnModalContentProps {
  code: string
}

export const AddOnModalContent: FunctionComponent<AddOnModalContentProps> = ({
  code,
}) => {
  const { scheme } = useProduct()
  const contentBaseUrl = useConfig(getExposuresContentBaseUrl(scheme.schemeCode))
  const iFrameSrc = `${contentBaseUrl}/${code}.html`

  return <HelpLinkIFrame src={iFrameSrc} />
}
