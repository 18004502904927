import { Box, ModalHeader } from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import { Data, PolicyAdjustmentResponse } from '../../../domain'
import { usePolicyAdjustment, usePolicyFormData } from '../../../hooks/adjustments/policy'
import {
  TExtends,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { AdjustmentInputConfig } from '../../../state/configuration'
import { FormDisabled } from '../../formBuilder'
import { AddOnsPath } from '../../product/policy/covers/AddOnsPath'
import { PolicyPath } from '../../product/policy/PolicyPath'

export interface EditOptionalCoverModalProps {
  inputs: AdjustmentInputConfig[]
  onComplete: () => void
}

const tKey = TKey('components.edit-optional-cover-modal')

export const PolicyEditOptionalCoverModal: FunctionComponent<
  EditOptionalCoverModalProps
> = ({ inputs, onComplete }) => {
  const { t } = useWriskTranslation()
  const navigate = useNavigate()

  const onInternalComplete = useCallback(
    async (response: PolicyAdjustmentResponse) => {
      if (response) {
        navigate(
          generatePath([PolicyPath.ADD_ONS, AddOnsPath.REVIEW].join('/'), {
            adjustmentId: response.adjustmentId,
          }),
        )
        onComplete()
      } else {
        onComplete()
      }
    },
    [navigate, onComplete],
  )

  const data = usePolicyFormData(inputs)
  const onSubmitAdjustment = usePolicyAdjustment(inputs, onInternalComplete)

  const onSubmit = useCallback(
    async (updatedData: Data) => {
      void onSubmitAdjustment.execute(updatedData)
    },
    [onSubmitAdjustment],
  )

  return (
    <Box>
      <ModalHeader header={t(tKey('header'))} />
      <FormDisabled
        id='editOptionalCover'
        data={data}
        inputs={inputs}
        onSubmit={onSubmit}
        loading={onSubmitAdjustment.loading}
        tKey={TExtends(tKey, 'inputs')}
        options={{ shouldUnregister: true }}
        t={t}
      />
    </Box>
  )
}
