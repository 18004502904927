import React, { FunctionComponent, useMemo } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { useCurrentPath } from '../../../../infrastructure/routing'
import { AppPath } from '../../../AppPath'
import { ProtectedRoutes } from '../../../authentication'
import { ProposalAddOnsPage } from '../covers'
import { ProposalPath } from '../ProposalPath'
import {
  RenewalDisclosureRouter,
  RenewalOptOutPage,
  RenewalSummaryPage,
} from '../renewal'

export const ProposalRenewalRoutes: FunctionComponent = () => {
  const currentPath = useCurrentPath()

  const homePage = useMemo(() => ({ url: AppPath.HOME, id: 'home' }), [])
  const summaryPage = useMemo(() => ({ url: currentPath, id: 'renewal' }), [currentPath])

  return (
    <ProtectedRoutes>
      <Routes>
        <Route key='quote' index element={<RenewalSummaryPage home={homePage} />} />
        <Route
          key='opt-out'
          path={ProposalPath.OPT_OUT}
          element={<RenewalOptOutPage parent={summaryPage} />}
        />

        <Route
          key='add-ons'
          path={ProposalPath.ADD_ONS}
          element={
            <ProposalAddOnsPage destination={summaryPage.url} parent={summaryPage} />
          }
        />

        <Route
          key='management'
          path={ProposalPath.DISCLOSURE + AppPath.WILDCARD}
          element={<RenewalDisclosureRouter parent={summaryPage} />}
        />

        <Route path='*' element={<Navigate to={AppPath.HOME} />} />
      </Routes>
    </ProtectedRoutes>
  )
}
