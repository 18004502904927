import {
  Box,
  BoxProps,
  EndorsementNotification,
  smBumps,
  Typo,
} from '@wrisk/ui-components'
import React from 'react'
import { Trans } from 'react-i18next'

import { getInsuredProfile, Quote } from '../../../domain'
import { Endorsement } from '../../../domain/types/endorsement'
import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'
import { useProduct } from '../../product/productContext'

interface ExcessEndorsementsProps extends BoxProps {
  quote: Quote
  newEndorsements?: Endorsement[]
}

const tKey = TKey('components.excess-endorsements')

export const ExcessEndorsements: React.FC<ExcessEndorsementsProps> = ({
  quote,
  newEndorsements,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const {
    product: { excess },
  } = useProduct()

  const excessEndorsements =
    newEndorsements ??
    quote.endorsements.filter((it) => excess?.endorsements.includes(it.code))

  return excessEndorsements.length ? (
    <Box {...props}>
      {excessEndorsements.map((it, i) => {
        const data = getInsuredProfile(quote, it.profileCode)?.data

        return data ? (
          <EndorsementNotification
            data-testid={`${it.code} ${it.profileCode}`}
            key={i}
            mt={i && smBumps}
          >
            <Typo>
              <Trans
                t={t}
                i18nKey={tKey(it.code)}
                values={{
                  nameOfDriver: `${data.firstName} ${data.lastName}`,
                }}
              />
            </Typo>
          </EndorsementNotification>
        ) : null
      })}
    </Box>
  ) : null
}
