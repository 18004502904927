import { Flex, Icon, smBumps, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  tFormats,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'

export const ExcessRow: FunctionComponent<{
  excess: number
  header: string
  showMinus?: boolean
}> = ({ excess, header, showMinus = false }) => {
  const { t } = useWriskTranslation()
  return (
    <Flex px={smBumps} py={2}>
      <Flex>
        {showMinus && <Icon icon='minus' size='small' mr={2} />}
        <Typo typoSize='sm'>{header}</Typo>
      </Flex>
      <Typo typoSize='sm'>{t(tFormats('currency.short'), { amount: excess })}</Typo>
    </Flex>
  )
}
