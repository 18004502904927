import React, { FunctionComponent } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Page } from '../../../../infrastructure/routing'
import { AdditionalProfileManagementConfig } from '../../../../state/configuration'
import {
  RenewalAdditionalProfileCreatePage,
  RenewalAdditionalProfilePage,
} from './RenewalAdditionalProfilePage'

export enum AdditionalProfilePath {
  Profile = 'profile/:profileCode',
  NewProfile = 'profile',
}

export interface RenewalAdditionalProfilePageProps {
  config: AdditionalProfileManagementConfig
  parent: Page
}

export const RenewalAdditionalProfileRouter: FunctionComponent<
  RenewalAdditionalProfilePageProps
> = ({ config, parent }) => (
  <Routes>
    <Route
      path={AdditionalProfilePath.Profile}
      element={<RenewalAdditionalProfilePage config={config} parent={parent} />}
    />
    <Route
      path={AdditionalProfilePath.NewProfile}
      element={<RenewalAdditionalProfileCreatePage config={config} parent={parent} />}
    />
    <Route path='*' element={<Navigate to={parent.url} />} />
  </Routes>
)
