import {
  Box,
  Form,
  mdBumps,
  Section,
  SectionContent,
  SectionTitle,
  xlBumps,
  xxlBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'

import { isRenewalProposal, RenewalProposal } from '../../../../domain'
import { useErrorHandlingAsyncCallback } from '../../../../hooks/errors'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { Page, useUpButton } from '../../../../infrastructure/routing'
import { toDateTime } from '../../../../util/date'
import { AppPath } from '../../../AppPath'
import { usePrincipal } from '../../../authentication'
import { ErrorMessage } from '../../../formBuilder'
import { CancellationReasonInput } from '../../../organisms/cancellation'
import { ActionBar } from '../../../organisms/form'
import { FullPage } from '../../../templates'
import { useProduct } from '../../productContext'
import { useProposal } from '../proposalContext'

const tKey = TKey('pages.renewal-opt-out')

interface OptOutFormData {
  reason: string
  other: string
}

const Content: FunctionComponent<{ parent: Page; proposal: RenewalProposal }> = ({
  parent,
  proposal,
}) => {
  const { t } = useWriskTranslation()

  const form = useForm<OptOutFormData>()

  const navigate = useNavigate()
  const upButton = useUpButton(parent)

  const {
    product: {
      renewal: { reasons },
    },
  } = useProduct()

  const onDismiss = useCallback(() => navigate(parent.url), [navigate, parent.url])

  const { apiClient } = usePrincipal()

  const onConfirm = useErrorHandlingAsyncCallback(async (data: OptOutFormData) => {
    await apiClient.setPolicyRenewalStop(proposal.sourcePolicy.policyId, data.reason)
    navigate(AppPath.HOME)
  })

  const subheader = (
    <Trans
      t={t}
      i18nKey={tKey('subheader')}
      values={{
        startAt: t(tFormats('datetime.medium'), {
          value: toDateTime(proposal.sourcePolicy.detail.expiredAt),
        }),
      }}
    />
  )

  return (
    <FullPage header={t(tKey('header'))} subheader={subheader} upButton={upButton}>
      <FormProvider {...form}>
        <Form formId='opt-out' onSubmit={form.handleSubmit(onConfirm.execute)}>
          <Section mb={xlBumps} width={1}>
            <SectionTitle>{t(tKey('sections', 'reason'))}</SectionTitle>
            <SectionContent>
              <Controller
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, name, value } }) => (
                  <CancellationReasonInput
                    name={name}
                    onChange={onChange}
                    reasons={reasons}
                    value={value}
                    mb={mdBumps}
                  />
                )}
                name='reason'
              />
              <ErrorMessage mt={3} name='reason' tName='reason' tKey={tKey} t={t} />
            </SectionContent>
          </Section>

          <Section mb={xxlBumps} width={1}>
            <SectionTitle>{t(tKey('sections', 'end'))}</SectionTitle>
            <SectionContent>
              <Box width={1} p={mdBumps} variant='raised'>
                {t(tFormats('datetime.medium'), { value: toDateTime(proposal.startAt) })}
              </Box>
            </SectionContent>
          </Section>

          <ActionBar
            destructive
            tKey={tKey}
            loading={onConfirm.loading}
            onDismiss={onDismiss}
          />
        </Form>
      </FormProvider>
    </FullPage>
  )
}

export const RenewalOptOutPage: FunctionComponent<{ parent: Page }> = ({ parent }) => {
  const { proposal } = useProposal()

  return isRenewalProposal(proposal) ? (
    <Content parent={parent} proposal={proposal} />
  ) : (
    <Navigate to={AppPath.HOME} />
  )
}
