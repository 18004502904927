import {
  Section,
  SectionContent,
  SectionDescription,
  SectionProps,
  SectionTitle,
  useModal,
} from '@wrisk/ui-components'
import React, { useCallback } from 'react'
import { UseAsyncReturn } from 'react-async-hook'

import { Quote } from '../../../domain'
import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'
import { ExcessConfig } from '../../../state/configuration'
import { InRenewalMtaBlockedModal } from '../InRenewalMtaBlockedModal'
import { MainExcesses } from './MainExcesses'
import { OtherExcesses } from './OtherExcesses'
import { VoluntaryExcessModalContent } from './VoluntaryExcessModalContent'

interface ExcessSectionProps extends SectionProps {
  config: ExcessConfig
  quote: Quote
  onExcessChange: UseAsyncReturn<void, [{ excess: number }]>
  isMtaAllowed?: boolean
}

const tKey = TKey('components.excess')

export const ExcessSection: React.FC<ExcessSectionProps> = ({
  config,
  quote,
  onExcessChange,
  isMtaAllowed = true,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const modal = useModal()

  const onEditVoluntaryExcess = useCallback(
    (value: number) => {
      isMtaAllowed
        ? modal.show({
            content: (
              <VoluntaryExcessModalContent
                excess={value}
                onExcessChange={onExcessChange}
                onClose={modal.hide}
                options={config?.voluntaryExcess ?? []}
              />
            ),
          })
        : modal.show({
            content: <InRenewalMtaBlockedModal />,
          })
    },
    [isMtaAllowed, modal, onExcessChange, config],
  )

  return (
    <Section width={1} {...props}>
      <SectionTitle>{t(tKey('header'))}</SectionTitle>
      <SectionDescription>{t(tKey('subheader'))}</SectionDescription>
      <SectionContent>
        <MainExcesses
          config={config}
          quote={quote}
          onExcessChange={onEditVoluntaryExcess}
        />
        <OtherExcesses quote={quote} />
      </SectionContent>
    </Section>
  )
}
