import { AxiosInstance } from 'axios'

import { PaymentMethod, RedirectFlow } from '../../../domain'
import { withGenericErrorHandling } from '../errors'

export interface CreateRedirectFlowParams {
  mandateDescription: string
  successUrl: string
}

export interface GoCardlessClient {
  createRedirectFlow: (params: CreateRedirectFlowParams) => Promise<RedirectFlow>
  getPaymentMethods: () => Promise<PaymentMethod[]>
  createPaymentMethod: (redirectFlowId: string) => Promise<PaymentMethod>
}

export const createGoCardlessClient = (
  getInstance: () => Promise<AxiosInstance>,
): GoCardlessClient => ({
  createRedirectFlow: (params) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.post<RedirectFlow>('/goCardless/flows', params)
      return response.data
    }),

  getPaymentMethods: () =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.get<PaymentMethod[]>(`/goCardless/mandates`)
      return response.data
    }),

  createPaymentMethod: (redirectFlowId) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.post<PaymentMethod>('/goCardless/mandates', {
        redirectFlowId,
      })
      return response.data
    }),
})
