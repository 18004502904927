import { FlexAnchor, FlexAnchorProps, mdBumps } from '@wrisk/ui-components'
import * as React from 'react'
import { FunctionComponent } from 'react'

export const MenuAnchor: FunctionComponent<FlexAnchorProps> = ({
  children,
  ref,
  ...props
}) => (
  <FlexAnchor
    flexDirection='column'
    alignItems='flex-start'
    width={1}
    px={mdBumps}
    py={3}
    variant='inset'
    {...props}
  >
    {children}
  </FlexAnchor>
)
