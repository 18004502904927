import {
  Box,
  Form,
  mdBumps,
  ModalHeader,
  PrimaryButton,
  RadioButton,
  RadioButtonGrid,
} from '@wrisk/ui-components'
import React from 'react'
import { useAsyncCallback, UseAsyncReturn } from 'react-async-hook'

import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'

interface ExcessModalContentProps {
  onClose: () => void
  onExcessChange: UseAsyncReturn<void, [{ excess: number }]>
  options: number[]
  excess: number
}

const tKey = TKey('components.voluntary-excess-modal')

export const VoluntaryExcessModalContent: React.FC<ExcessModalContentProps> = ({
  onClose,
  onExcessChange,
  options,
  excess,
}) => {
  const { t } = useWriskTranslation()

  const onSubmitCallback = useAsyncCallback(async (e) => {
    const newExcess = Number(new FormData(e.currentTarget).get('excess'))
    if (newExcess !== excess) await onExcessChange.execute({ excess: newExcess })
    onClose()
  })

  const radioInput = (
    <RadioButtonGrid width={1} columns={4}>
      {options.map((it) => (
        <RadioButton
          key={it}
          name='excess'
          value={it}
          id={it.toString()}
          defaultChecked={it === excess}
          data-testid={`excess${it}`}
        >
          {t(tFormats('currency.short'), { amount: it })}
        </RadioButton>
      ))}
    </RadioButtonGrid>
  )

  return (
    <Box>
      <ModalHeader header={t(tKey('header'))} />
      <Form formId='excess-form' onSubmit={onSubmitCallback.execute}>
        {radioInput}
        <PrimaryButton
          loading={onSubmitCallback.loading}
          type='submit'
          data-testid='modal-button-save'
          mt={mdBumps}
          width={1}
          layout='fixed'
        >
          {t(tKey('actions.confirm'))}
        </PrimaryButton>
      </Form>
    </Box>
  )
}
