import { Box, ModalHeader, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  CustomerCheckResults,
  CustomerCheckResultType,
} from '../../../../../../domain/types/customerCheck'
import {
  TKey,
  useWriskTranslation,
} from '../../../../../../infrastructure/internationalisation'

const tKey = TKey('components.verification-failed-modal')

export interface VerificationFailedModalProps {
  checks: CustomerCheckResults
}

export const VerificationFailedModal: FunctionComponent<VerificationFailedModalProps> = ({
  checks,
}) => {
  const { t } = useWriskTranslation()

  const failures = Object.entries(checks)
    .filter(([, value]) => value.result === CustomerCheckResultType.CONSIDER)
    .map(([key]) => key)

  const defaultMessage = t(tKey('defaultMessage'))

  const message =
    failures.length === 1
      ? t(tKey('messages', failures[0]), { defaultValue: defaultMessage })
      : defaultMessage

  return (
    <Box>
      <ModalHeader header={t(tKey('header'))} />
      <Typo>{message}</Typo>
    </Box>
  )
}
