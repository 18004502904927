import {
  Box,
  BoxProps,
  Flex,
  Heading,
  LinkAnchor,
  mdBumps,
  SectionContent,
  useModal,
} from '@wrisk/ui-components'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { getCoreAsset, Quote, RiskProfileStatus } from '../../../../../../../domain'
import { useIsMtaAllowed } from '../../../../../../../hooks/adjustments/policy/renewal'
import { useOptionalCovers } from '../../../../../../../hooks/covers'
import {
  TKey,
  useWriskTranslation,
} from '../../../../../../../infrastructure/internationalisation'
import { OptionalCoverItem } from '../../../../../../organisms/covers'
import { InRenewalMtaBlockedModal } from '../../../../../../organisms/InRenewalMtaBlockedModal'
import { useProduct } from '../../../../../productContext'
import { ProposalPath } from '../../../../../proposal/ProposalPath'
import { AddOnsPromo } from './AddOnsPromo'

interface AddOnsSectionProps extends BoxProps {
  quote: Quote
  contentBaseUrl: string
}

const tKey = TKey('components.add-ons')

export const AddOnsSection: React.FC<AddOnsSectionProps> = ({
  quote,
  contentBaseUrl,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const navigate = useNavigate()

  const modal = useModal()
  const { product } = useProduct()
  const isMtaAllowed = useIsMtaAllowed()

  const asset = getCoreAsset(quote)

  const addOns = useOptionalCovers(quote, product, true)

  const addOnsToSelect = addOns.filter(
    (it) => it.ratingStatus === RiskProfileStatus.QUOTE && !it.active,
  )
  const addOnsSelected = addOns.filter((it) => it.active)

  const onUpdateClick = useCallback(() => {
    isMtaAllowed
      ? navigate(ProposalPath.ADD_ONS)
      : modal.show({
          content: <InRenewalMtaBlockedModal />,
        })
  }, [isMtaAllowed, modal, navigate])

  return (
    <>
      {addOnsToSelect.length > 0 && (
        <Box width={1} mb={mdBumps}>
          {addOnsToSelect.map((cover) => (
            <AddOnsPromo
              key={cover.coverCode}
              asset={asset}
              contentBaseUrl={contentBaseUrl}
              cover={cover}
            />
          ))}
        </Box>
      )}

      {addOnsSelected.length > 0 && (
        <Box width={1} {...props}>
          <Flex width={1}>
            <Heading variant='h3' typoSize='lg'>
              {t(tKey('header'))}
            </Heading>
            <LinkAnchor variant='standalone' typoSize='sm' onClick={onUpdateClick}>
              {t(tKey('update'))}
            </LinkAnchor>
          </Flex>

          <SectionContent>
            <Flex width={1} variant='raised' p={2} flexWrap='wrap'>
              {addOnsSelected.map((cover) => (
                <OptionalCoverItem
                  width={1}
                  key={cover.coverCode}
                  contentBaseUrl={contentBaseUrl}
                  cover={cover}
                />
              ))}
            </Flex>
          </SectionContent>
        </Box>
      )}
    </>
  )
}
