import { Flex, FlexProps, mdBumps, smBumps, Typo } from '@wrisk/ui-components'
import { isEqual } from 'lodash'
import React, { FunctionComponent } from 'react'

import { getEndorsements, getPolicyVersion, PolicyVersion } from '../../../domain'
import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'
import { usePolicy } from '../../product/policy/policyContext'
import { ExcessEndorsements } from '../excess'

interface AdjustmentProfileExcessProps extends FlexProps {
  updatedPolicyVersion: PolicyVersion
  profileCodes?: string[]
}

const tKey = TKey('components.adjustment-profile-excess')

export const AdjustedProfileExcess: FunctionComponent<AdjustmentProfileExcessProps> = ({
  updatedPolicyVersion,
}) => {
  const { t } = useWriskTranslation()
  const { policy } = usePolicy()

  const { quote: originalQuote } = getPolicyVersion(policy)
  const { quote: updatedQuote } = updatedPolicyVersion

  const originalEndorsements = getEndorsements(originalQuote)
  const updatedEndorsements = getEndorsements(updatedQuote)

  const newEndorsements = updatedEndorsements.filter(
    (updatedEndorsement) =>
      !originalEndorsements.some((originalEndorsement) =>
        isEqual(originalEndorsement, updatedEndorsement),
      ),
  )

  return newEndorsements.length ? (
    <Flex
      p={mdBumps}
      mt={mdBumps}
      width={1}
      variant='raised'
      flexDirection='column'
      alignItems='flex-start'
    >
      <Typo fontWeight='bold' mb={smBumps}>
        {t(tKey('header'))}
      </Typo>
      <ExcessEndorsements quote={updatedQuote} newEndorsements={newEndorsements} />
    </Flex>
  ) : null
}
