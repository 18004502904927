import { generatePath, useNavigate } from 'react-router-dom'

import { AppPath } from '../application/AppPath'
import { usePrincipal } from '../application/authentication'
import { ProposalPath } from '../application/product/proposal/ProposalPath'
import { isProposalUpgradable, isValidSourceProposal, Proposal } from '../domain'
import { getSchemeByCode, Scheme, useConfig } from '../state/configuration'
import { useErrorHandlingAsyncCallback } from './errors'
import { useNavigateCallback } from './navigation'

export const useCreateProposalCallback = ({
  productUrlName: productUrlName,
  schemeCode,
}: Scheme) => {
  const navigate = useNavigate()

  const { refreshAnonymous, apiClient } = usePrincipal()

  return useErrorHandlingAsyncCallback(async () => {
    await refreshAnonymous()

    const proposal = await apiClient.createProposal({ schemeCode })
    navigate(
      generatePath(AppPath.PROPOSAL, {
        productUrlName,
        proposalCode: proposal.proposalCode,
      }),
    )
    return proposal
  })
}

export const useContinueProposalCallback = (proposal: Proposal) => {
  const { productUrlName } = useConfig(getSchemeByCode(proposal.schemeCode))
  const upgradable = isProposalUpgradable(proposal)

  const paths = [
    generatePath(AppPath.PROPOSAL, {
      proposalCode: proposal.proposalCode,
      productUrlName,
    }),
    upgradable && ProposalPath.UPGRADE,
  ].filter((it): it is string => Boolean(it))

  return useNavigateCallback(...paths)
}

export const useEditProposalCallback = (proposal: Proposal) => {
  const { proposalCode, schemeCode } = isValidSourceProposal(proposal.sourceProposal)
    ? proposal.sourceProposal
    : proposal

  const { productUrlName } = useConfig(getSchemeByCode(schemeCode))

  const paths = [
    generatePath(AppPath.PROPOSAL, {
      productUrlName,
      proposalCode,
    }),
    ProposalPath.DISCLOSURE,
  ]

  return useNavigateCallback(...paths)
}
