import { InsuredAsset, InsuredProfile, Quote } from './types'
import { Endorsement } from './types/endorsement'

export const getInsuredProfile = (quote: Quote, profileCode: string | undefined) =>
  quote.insuredProfiles.find((it) => it.profileCode === profileCode)

export const getInsuredProfileStrict = (quote: Quote, profileCode: string) => {
  const profile = getInsuredProfile(quote, profileCode)
  if (!profile) throw new Error(`Quote does not have a profile ${profileCode}`)
  return profile
}

export const getAdditionalProfiles = (quote: Quote) =>
  quote.insuredProfiles.filter((it) => !it.policyholder)

export const getPolicyholder = (quote: Quote): InsuredProfile => {
  const policyholder = quote.insuredProfiles.find((it) => it.policyholder)

  if (!policyholder) {
    throw new Error('Quote does not have a policyholder')
  }

  return policyholder
}

export const getCoreAsset = (quote: Quote): InsuredAsset => {
  const asset = quote.insuredAssets.find((it) => it.coreAsset)

  if (!asset) {
    throw new Error('Quote does not have a core asset')
  }

  return asset
}

export const getEndorsements = (quote: Quote): Endorsement[] => {
  const endorsements = quote.endorsements

  if (!endorsements) {
    throw new Error('Quote does not have endorsements')
  }

  return endorsements
}
