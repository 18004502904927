import { Box, Flex, Icon, mdBumps, smBumps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { getCoreAsset, Quote } from '../../../../domain'
import { isMainExcess } from '../../../../domain/excess'
import { ExcessConfig } from '../../../../state/configuration'
import { allEqual } from '../../../../util/array'
import { ExcessEndorsements } from '../ExcessEndorsements'
import { VoluntaryExcess } from '../VoluntaryExcess'
import { MainExcessBreakdown } from './MainExcessBreakdown'
import { MainExcessSummary } from './MainExcessSummary'

export interface ExcessProps {
  config: ExcessConfig
  quote: Quote
  onExcessChange: (excess: number) => void
}

export const MainExcesses: React.FC<ExcessProps> = ({
  config,
  quote,
  onExcessChange,
}) => {
  const asset = getCoreAsset(quote)

  const excesses = asset.mandatoryExcessLines.filter(isMainExcess)
  const excessValues = excesses.map((it) => it.excess)
  const areAllExcessesEqual = allEqual(excessValues)

  return (
    <Box width={1}>
      <Flex
        flexDirection={areAllExcessesEqual ? ['column', 'row'] : 'column'}
        alignItems='stretch'
      >
        {areAllExcessesEqual ? (
          <MainExcessSummary value={excessValues[0]} />
        ) : (
          <MainExcessBreakdown excesses={excesses} />
        )}
        {Boolean(config.voluntaryExcess?.length) && (
          <>
            <Divider />
            <VoluntaryExcess config={config} quote={quote} onEdit={onExcessChange} />
          </>
        )}
      </Flex>
      <ExcessEndorsements quote={quote} mt={mdBumps} />
    </Box>
  )
}

const Divider: FunctionComponent = () => (
  <Flex justifyContent='center' alignItems='center'>
    <Icon m={smBumps} icon='plus' size='small' />
  </Flex>
)
