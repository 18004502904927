import {
  Box,
  Flex,
  FlexAnchor,
  FlexAnchorProps,
  HelpLinkIFrame,
  Icon,
  ModalHeader,
  smBumps,
  TertiaryButton,
  Typo,
  useModal,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'
import { Trans } from 'react-i18next'

import { PriceOption, RiskProfileStatus } from '../../../domain'
import {
  tCovers,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { getCoreAssetType } from '../../../state/configuration'
import { usePrincipal } from '../../authentication'
import { useProduct } from '../../product/productContext'
import { useMaybeProposal } from '../../product/proposal/proposalContext'
import { PolicyEditOptionalCoverModal } from './PolicyEditOptionalCoverModal'
import { ProposalEditOptionalCoverModal } from './ProposalEditOptionalCoverModal'

interface ProposalOptionalCoverItemProps extends Omit<FlexAnchorProps, 'ref'> {
  cover: PriceOption
  contentBaseUrl: string
}

export const OptionalCoverItem: FunctionComponent<ProposalOptionalCoverItemProps> = ({
  cover,
  contentBaseUrl,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const modal = useModal()
  const { isAdmin } = usePrincipal()
  const { product } = useProduct()
  const { covers } = getCoreAssetType(product)

  const optionalCover = covers.optional?.find((it) => it.code === cover.coverCode)

  const onComplete = useCallback(() => {
    modal.hide()
  }, [modal])

  const proposal = useMaybeProposal()

  const onEdit = useCallback(
    (e) => {
      e.stopPropagation()
      e.preventDefault()

      if (proposal) {
        modal.show({
          content: (
            <ProposalEditOptionalCoverModal
              inputs={optionalCover?.inputs ?? []}
              onComplete={onComplete}
            />
          ),
        })
      } else {
        modal.show({
          content: (
            <PolicyEditOptionalCoverModal
              inputs={optionalCover?.inputs ?? []}
              onComplete={onComplete}
            />
          ),
        })
      }
    },
    [proposal, modal, optionalCover, onComplete],
  )

  const inReferral = cover.ratingStatus === RiskProfileStatus.REFERRAL

  const iconName = !inReferral && cover.active ? 'tick' : 'cross'
  const color = !inReferral && cover.active ? undefined : 'inactive'

  const isAdjustable = Boolean(
    optionalCover?.inputs && !inReferral && (proposal || isAdmin),
  )

  const onClick = useCallback(() => {
    cover.ratingStatus === RiskProfileStatus.REFERRAL
      ? modal.show({
          content: (
            <Box>
              <ModalHeader header={t(tCovers(cover.coverCode, 'name'))} />
              <Typo>
                <Trans t={t} i18nKey={tCovers(cover.coverCode, 'unavailable')} />
              </Typo>
            </Box>
          ),
        })
      : modal.show({
          content: <HelpLinkIFrame src={`${contentBaseUrl}/${cover.coverCode}.html`} />,
          variant: 'iframe',
        })
  }, [contentBaseUrl, cover, modal, t])

  return (
    <FlexAnchor
      variant='highlight'
      py={3}
      px={smBumps}
      columnGap={2}
      onClick={onClick}
      {...props}
    >
      <Flex>
        <Icon icon={iconName} color={color} mr={smBumps} />
        <Typo color={color}>{t(tCovers(cover.coverCode, 'name'))}</Typo>
      </Flex>
      {isAdjustable && (
        <TertiaryButton layout='small' onClick={onEdit}>
          {t(tCovers(cover.coverCode, 'actions.edit'))}
        </TertiaryButton>
      )}
    </FlexAnchor>
  )
}
