import { generatePath, useNavigate } from 'react-router-dom'

import { usePrincipal } from '../../../application/authentication'
import { ExcessPath } from '../../../application/product/policy/excess/ExcessPath'
import { usePolicy } from '../../../application/product/policy/policyContext'
import { PolicyPath } from '../../../application/product/policy/PolicyPath'
import { PolicyChangeType } from '../../../domain'
import { useApiErrorHandlingAsyncCallback } from '../../auth'

export const usePolicyExcessAdjustment = () => {
  const navigate = useNavigate()

  const { apiClient } = usePrincipal()
  const { policy } = usePolicy()

  return useApiErrorHandlingAsyncCallback(async ({ excess }: { excess: number }) => {
    const { adjustmentId } = await apiClient.createPolicyAdjustment({
      policyId: policy.policyId,
      adjustmentType: 'MTA',
      changes: [
        {
          changeType: PolicyChangeType.SELECT_VOLUNTARY_EXCESS,
          excess,
        },
      ],
    })
    navigate(
      generatePath([PolicyPath.EXCESS, ExcessPath.REVIEW].join('/'), { adjustmentId }),
    )
  })
}
