import { Box, BoxProps, mdBumps, Typo } from '@wrisk/ui-components'
import { negate } from 'lodash'
import React, { FunctionComponent } from 'react'

import { getCoreAsset, Quote } from '../../../domain'
import { isMainExcess } from '../../../domain/excess'
import {
  tExcesses,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { allEqual } from '../../../util/array'
import { ExcessEndorsements } from './ExcessEndorsements'
import { ExcessRow } from './ExcessRow'

export interface ExcessSummaryProps extends BoxProps {
  quote: Quote
}

const tKey = TKey('components.excess-summary')

export const ExcessSummary: FunctionComponent<ExcessSummaryProps> = ({
  quote,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const { mandatoryExcessLines, voluntaryExcess } = getCoreAsset(quote)

  const mainExcessValues = mandatoryExcessLines
    .filter(isMainExcess)
    .map((it) => it.excess)
  const isMainExcessAllEqual = allEqual(mainExcessValues)

  const mainExcessSection = isMainExcessAllEqual ? (
    <ExcessRow excess={mainExcessValues[0]} header={t(tKey('headings.compulsory'))} />
  ) : (
    <>
      <Box px={mdBumps} py={2}>
        <Typo typoSize='sm'>{t(tKey('headings.compulsory'))}</Typo>
      </Box>
      {mandatoryExcessLines
        .filter(isMainExcess)
        .filter((it) => it.excess > 0)
        .map((it) => (
          <ExcessRow
            key={it.excessCategory}
            excess={it.excess}
            header={t(tExcesses(it.excessCategory, 'name'))}
            showMinus={true}
          />
        ))}
    </>
  )

  const voluntaryExcessSection = (
    <ExcessRow excess={voluntaryExcess} header={t(tKey('headings.voluntary'))} />
  )

  const otherExcessSection = mandatoryExcessLines
    .filter(negate(isMainExcess))
    .map((it) => (
      <ExcessRow
        key={it.excessCategory}
        excess={it.excess}
        header={t(tExcesses(it.excessCategory, 'name'))}
      />
    ))

  const endorsementsSection = <ExcessEndorsements quote={quote} my={3} />

  return (
    <Box width={1} {...props}>
      {mainExcessSection}
      {voluntaryExcessSection}
      {otherExcessSection}
      {endorsementsSection}
    </Box>
  )
}
