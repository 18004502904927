import {
  Box,
  Grid,
  Heading,
  mdBumps,
  Section,
  SectionContent,
  SectionDescription,
  SectionProps,
  SectionTitle,
  smBumps,
  xsBumps,
} from '@wrisk/ui-components'
import React from 'react'

import { Quote } from '../../../domain'
import { useIncludedCovers } from '../../../hooks/covers'
import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'
import { getCoreAssetType } from '../../../state/configuration'
import { useProduct } from '../../product/productContext'
import { Endorsements } from './Endorsements'
import { IncludedCoverItems } from './IncludedCoverItems'
import { IncludedCoverItemsWithInfo } from './IncludedCoverItemsWithInfo'

export interface ProposalCoverSectionProps extends SectionProps {
  quote: Quote
  contentBaseUrl: string
  optionalCoverComponents: React.JSX.Element[]
}

const tKey = TKey('components.cover.comprehensive-core')

export const CoverSection: React.FC<ProposalCoverSectionProps> = ({
  quote,
  contentBaseUrl,
  optionalCoverComponents,
  ...props
}) => {
  const { t } = useWriskTranslation()
  const { product } = useProduct()
  const { covers } = getCoreAssetType(product)

  const includedCovers = useIncludedCovers(product)

  const includedCoverItems = covers.withInfo ? (
    <IncludedCoverItemsWithInfo includedCovers={includedCovers} />
  ) : (
    <IncludedCoverItems includedCovers={includedCovers} contentBaseUrl={contentBaseUrl} />
  )

  const subheader = t(tKey('subheader'), { defaultValue: null })

  return (
    <Section width={1} {...props}>
      <SectionTitle>{t(tKey('header'))}</SectionTitle>
      {subheader && <SectionDescription>{subheader}</SectionDescription>}
      <SectionContent>
        <Box width={1} variant='raised'>
          {includedCoverItems}
          {optionalCoverComponents.length > 0 && (
            <Box width={1} p={2} borderTopWidth={1}>
              <Heading mt={2} px={smBumps} pb={xsBumps} variant='h3'>
                {t(tKey('optional.header'))}
              </Heading>
              <Grid
                width={1}
                gridTemplateColumns='1fr'
                gridTemplateRows='auto'
                alignItems='stretch'
              >
                {optionalCoverComponents}
              </Grid>
            </Box>
          )}
        </Box>
      </SectionContent>
      <Endorsements quote={quote} mt={mdBumps} />
    </Section>
  )
}
